import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon,
  ListItemText, Menu, MenuItem, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { Build, Close, Info, MoreHoriz } from '@mui/icons-material'
import { ROLE_CAPTAIN, ROLE_MECHANICS, TEST_COLLECTION } from '../../_constants/globals'
import { TESTS } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import TestRequestDialog from './TestRequestDialog'
import { getRoleIcon } from '../../_helpers/dataHelper'
import { isNil } from 'lodash/lang'
import { useFirestore } from '../../hooks/useFirestore'
import { useSelector } from 'react-redux'
import TestInfoDialog from './TestInfoDialog'


const ActionBar = ({ test, selectedTab, setSelectedTab, mechanicTasks, captainTasks, readyToFly }) => {

  const { id } = test
  const navigate = useNavigate()
  const testHooks = useFirestore(TEST_COLLECTION)
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const [anchorEl, setAnchorEl] = useState(null)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  const closeMenu = () => setAnchorEl(null)

  const handleDelete = async () => {
    navigate(TESTS)
    await testHooks.deleteDoc(id)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, width: '100%', mt: 3 }}>
      <Tabs value={selectedTab} onChange={(_, val) => setSelectedTab(val)}>
        <Tab label='Overview' value={0} />
        {test?.offBlocksUtc
          ? <Tab label='TLB' value={1} />
          : <Tab label='Briefing' value={2} />
        }
      </Tabs>
      <Box sx={{ float: 'right' }}>
        {readyToFly && <Typography display='inline-flex'>👍 Ready to fly</Typography>}
        {<Tooltip title='Mechanic work to do'>
          <IconButton sx={{ mx: 1 }}>
            <Badge
              badgeContent={mechanicTasks}
              color={mechanicTasks ? 'error' : 'success'}
              variant={mechanicTasks ? 'standard' : 'dot'}
              showZero
            >
              {getRoleIcon(ROLE_MECHANICS)}
            </Badge>
          </IconButton>
        </Tooltip>}
        {!isNil(captainTasks) && <Tooltip title='Captain work to do'>
          <IconButton sx={{ mx: 1 }}>
            <Badge
              badgeContent={captainTasks}
              color={captainTasks ? 'error' : 'success'}
              variant={captainTasks ? 'standard' : 'dot'}
              showZero
            >
              {getRoleIcon(ROLE_CAPTAIN)}
            </Badge>
          </IconButton>
        </Tooltip>}
        <Button variant='contained' disabled={readOnlyMode} onClick={e => setAnchorEl(e.currentTarget)} sx={{ bgcolor: 'grey.300', p: 1, ml: 3 }}>
          <MoreHoriz sx={{ color: 'black' }} />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={closeMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => {setEditOpen(true); closeMenu()}}>
            <ListItemIcon><Build /></ListItemIcon>
            <ListItemText>Setup</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {setInfoOpen(true); closeMenu()}}>
            <ListItemIcon><Info /></ListItemIcon>
            <ListItemText>Info</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {setConfirmDeleteOpen(true); closeMenu()}}>
            <ListItemIcon><Close /></ListItemIcon>
            <ListItemText>Supprimer</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      {confirmDeleteOpen && <Dialog open onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>Es-tu sûr de vouloir supprimer le test ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Toutes les inscriptions seront aussi supprimées
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)}>Annuler</Button>
          <Button onClick={handleDelete} autoFocus>Confirmer</Button>
        </DialogActions>
      </Dialog>}
      {editOpen && <TestRequestDialog open onClose={() => setEditOpen(false)} test={test} />}
      {infoOpen && <TestInfoDialog open onClose={() => setInfoOpen(false)} test={test} />}
    </Box>
  )
}

ActionBar.propTypes = {
  test: PropTypes.object,
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
  mechanicTasks: PropTypes.number,
  captainTasks: PropTypes.number,
  readyToFly: PropTypes.bool,
}

export default ActionBar
