import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardHeader, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TEST_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import { Cancel, CheckCircle, Edit } from '@mui/icons-material'
import { formatDuration, parseISODuration } from '../../_helpers/dataHelper'
import { format, formatISODuration, intervalToDuration, parse, formatDuration as formatDurationFNS } from 'date-fns'
import { pickBy } from 'lodash'


const FlightParamsCard = ({ withCER }) => {

  const selectedTestId = useSelector(state => state.data.selectedTestId)
  const testHooks = useFirestore(TEST_COLLECTION)
  const tests = testHooks.getDocs()
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const test = useMemo(() => tests?.find(t => t.id === selectedTestId), [tests, selectedTestId])

  const [editMode, setEditMode] = useState(!test?.offBlocksTime && !test?.takeOffTime && !test?.landingTime && !test?.onBlocksTime && !readOnlyMode)
  const [offBlocksTime, setOffBlocksTime] = useState('')
  const [takeOffTime, setTakeOffTime] = useState('')
  const [landingTime, setLandingTime] = useState('')
  const [onBlocksTime, setOnBlocksTime] = useState('')
  const [cerDuration, setCerDuration] = useState('')
  const [landings, setLandings] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    setOffBlocksTime(test?.offBlocksTime ? format(test.offBlocksTime, 'HH:mm') : '')
    setTakeOffTime(test?.takeOffTime ? format(test.takeOffTime, 'HH:mm') : '')
    setLandingTime(test?.landingTime ? format(test.landingTime, 'HH:mm') : '')
    setOnBlocksTime(test?.onBlocksTime ? format(test.onBlocksTime, 'HH:mm') : '')
    setCerDuration(test?.cerDuration ? formatDuration(parseISODuration(test.cerDuration)) : '')
    setLandings(test?.landings || '')
    setSubmitted(false)
  }, [test])

  const blockTime = useMemo(() => {
    if (offBlocksTime && onBlocksTime) {
      try {
        const duration = intervalToDuration({ start: parse(offBlocksTime, 'HH:mm', test.startDate), end: parse(onBlocksTime, 'HH:mm', test.startDate) })
        return formatDurationFNS(duration)
      } catch (e) {}
    }
  }, [offBlocksTime, onBlocksTime])

  const flightTime = useMemo(() => {
    if (takeOffTime && landingTime) {
      try {
        const duration = intervalToDuration({ start: parse(takeOffTime, 'HH:mm', test.startDate), end: parse(landingTime, 'HH:mm', test.startDate) })
        return formatDurationFNS(duration)
      } catch (e) {}
    }
  }, [takeOffTime, landingTime])

  const handleSubmit = () => testHooks.updateDoc(test.id, pickBy({
    offBlocksTime: offBlocksTime && parse(offBlocksTime, 'HH:mm', test.startDate),
    takeOffTime: takeOffTime && parse(takeOffTime, 'HH:mm', test.startDate),
    landingTime: landingTime && parse(landingTime, 'HH:mm', test.startDate),
    onBlocksTime: onBlocksTime && parse(onBlocksTime, 'HH:mm', test.startDate),
    landings: landings && Number(landings),
    cerDuration: cerDuration && formatISODuration({ hours: Number(cerDuration.split(':')[0]), minutes: Number(cerDuration.split(':')[1]) }),
  }))
    .then(() => setEditMode(false))
    .finally(() => setSubmitted(false))

  return (
    <Card>
      <CardHeader
        title='Parameters'
        action={editMode
          ? <>
            <IconButton onClick={() => setEditMode(false)} disabled={submitted} color='error'><Cancel /></IconButton>
            <IconButton onClick={handleSubmit} disabled={submitted} color='success'><CheckCircle /></IconButton>
          </>
          : <IconButton disabled={readOnlyMode} onClick={() => setEditMode(true)}><Edit /></IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='offBlocksUtc'
                label='Off Blocks'
                placeholder='13:01'
                fullWidth
                value={offBlocksTime || ''}
                onChange={e => setOffBlocksTime(e.target.value)}
              />
              : <>
                <Typography variant='caption'>Off Blocks</Typography>
                <Typography fontWeight='bold'>{offBlocksTime}</Typography>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='onBlocksUtc'
                label='On Blocks'
                placeholder='13:04'
                fullWidth
                value={onBlocksTime || ''}
                onChange={e => setOnBlocksTime(e.target.value)}
              />
              : <>
                <Typography variant='caption'>On Blocks</Typography>
                <Typography fontWeight='bold'>{onBlocksTime}</Typography>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            <Typography>Block time</Typography>
            <Typography fontWeight='bold'>{blockTime}</Typography>
          </Grid>
          <Grid item xs={4}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='takeOffUtc'
                label='Take off'
                placeholder='13:02'
                fullWidth
                value={takeOffTime || ''}
                onChange={e => setTakeOffTime(e.target.value)}
              />
              : <>
                <Typography variant='caption'>Take off</Typography>
                <Typography fontWeight='bold'>{takeOffTime}</Typography>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='landingUtc'
                label='Landing'
                placeholder='13:03'
                fullWidth
                value={landingTime || ''}
                onChange={e => setLandingTime(e.target.value)}
              />
              : <>
                <Typography variant='caption'>Landing</Typography>
                <Typography fontWeight='bold'>{landingTime}</Typography>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            <Typography>Flight time</Typography>
            <Typography fontWeight='bold'>{flightTime}</Typography>
          </Grid>
          {withCER && <>
            <Grid item xs={8}>
              {editMode
                ? <TextField
                  variant='standard'
                  margin='dense'
                  id='cerTime'
                  label='CER Time'
                  placeholder='01:45'
                  fullWidth
                  value={cerDuration || ''}
                  onChange={e => setCerDuration(e.target.value)}
                />
                : <>
                  <Typography variant='caption'>CER Time (hours)</Typography>
                  <Typography fontWeight='bold'>{cerDuration}</Typography>
                </>
              }
            </Grid>
            <Grid item xs={4}>
              <Typography>CER time</Typography>
              <Typography fontWeight='bold'>{cerDuration}</Typography>
            </Grid>
          </>}
          <Grid item xs={12}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='landings'
                label='Landings'
                placeholder='1'
                fullWidth
                type='number'
                value={landings}
                onChange={e => setLandings(e.target.value)}
              />
              : <>
                <Typography variant='caption'>Landings</Typography>
                <Typography fontWeight='bold'>{landings}</Typography>
              </>
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

FlightParamsCard.propTypes = {
  withCER: PropTypes.bool,
}

export default FlightParamsCard
