import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Box, useTheme, useMediaQuery } from '@mui/material'
import { isDate, isObject } from 'lodash'
import { format } from 'date-fns'


export const TestInfoDialog = ({ open, onClose, test }) => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  
  useEffect(() => { console.info('test', test)}, [test])
  
  const printObj = (obj, tab = 0) => Object.keys(obj).filter(k => !k.includes('Ref')).map(key =>
    <Box key={key}>
      <Box component='span' sx={{ fontWeight: 'bold', ml: tab }}>{key} : </Box>
      {isDate(obj[key])
        ? format(obj[key], 'yyyy-MM-dd HH:mm')
        : isObject(obj[key])
          ? printObj(obj[key], tab + 2)
          : <Box component='span'>{obj[key]}</Box>
      }
    </Box>,
  )
  
  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
      sx={{
        '& .MuiDialog-paperWidthSm': {
          maxWidth: 'unset',
        },
      }}
    >
      <Box p={1} pb={{ xs: '25px', md: 1 }}>
        {printObj(test)}
      </Box>
    </Dialog>
  )
}

TestInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  test: PropTypes.object,
}

export default TestInfoDialog
