import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'


export const PotentialGraph = ({ aircraft, sx }) => {

  const { last, next, margin, counter } = useMemo(() => ({
    last: aircraft.followUp?.lastCheckupCounter || 0,
    next: aircraft.followUp?.nextCheckupCounter || 0,
    margin: aircraft.followUp?.margin || 0,
    counter: aircraft.flightHours || 0,
  }), [aircraft])
  const yellowWidth = useMemo(() => counter > next + margin ? (next + margin - last) / (counter - last) * 100 : 100, [last, next, margin, counter])
  const greenWidth = useMemo(() => counter > next + margin
    ? (next - last) / (counter - last) * 100
    : (next - last) / (next - last + margin) * 100,
  [last, next, margin, counter])

  return (
    <Box sx={{ width: '100%', mb: 2, mt: 1, ...sx }}>
      <Box sx={{ width: '100%', height: '20px', position: 'relative' }}>
        {counter > next + margin && <Box sx={{ width: '100%', height: '100%', position: 'absolute', bgcolor: 'error.light' }} />}
        <Box sx={{ height: '100%', position: 'absolute', bgcolor: 'warning.light', width: `${yellowWidth}%` }} />
        <Box sx={{ height: '100%', position: 'absolute', bgcolor: 'success.light', width: `${greenWidth}%` }} >
          <Box component='span' sx={{ fontSize: '.7rem', mt: '20px', mx: '-1rem', float: 'left' }}>{last}</Box>
          <Box component='span' sx={{ float: 'right', fontSize: '.7rem', mt: '20px', mr: '-1rem' }}>{next}</Box>
        </Box>
        <Box sx={{
          height: '26px',
          width: '2px',
          bgcolor: 'black',
          position: 'absolute',
          top: '-3px',
          right: counter > next + margin ? 0 : 'inherit',
          left: counter > next + margin ? 'inherit' : `${(counter - last) / (next + margin - last) * 100}%`,
        }}>
          <Box component='span' sx={{ float: 'right', fontSize: '.7rem', mt: '-15px', mr: '-1rem' }}>{counter.toFixed(2)}</Box>
        </Box>
      </Box>
    </Box>
  )
}


PotentialGraph.propTypes = {
  aircraft: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

export default PotentialGraph
